import React, { useContext, useState, useEffect } from 'react';
import { Container, Button, ButtonGroup } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { greetings, networks } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [greetIdx, setGreetIdx] = useState(0);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  if(!greetings) {
    // TODO(snitkdan): add a loading icon
    return (
      <section id="hero" className="jumbotron"></section>
    )
  }

  const {hello, name, lang} = greetings[greetIdx];

  var first, second;
  if (lang === '日本語') {
    first = <span className="text-color-main">{name}</span>;
    second = hello;
  } else {
    first = hello;
    second = <span className="text-color-main">{name}</span>;
  }

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {'👋🏻 '}{first}{' '}
            <br />
            {second}
            <br />
          </h1>
                    <div className="hero-cta">
            <ButtonGroup size="lg" aria-label="Basic example">
            {
              greetings.map((obj, i) =>
                <Button key={i} className="cta-btn cta-btn--hero" onClick={() => { setGreetIdx(i); }}>{obj.lang}</Button>
              )
            }
            </ButtonGroup>
          </div>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">

          <div className="social-links">
            {networks &&
              networks.map((network) => {
                const { id, name, url } = network;
                return (
                  <a
                  key={id}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                  >
                    <i className={`fa fa-${name || 'refresh'}`} />
                  </a>
                );
 
              })}
          </div>
        </Fade>
      </Container>

    </section>
  );
};

export default Header;
